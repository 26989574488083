<template>
    <div>
        <v-layout row justify-space-between>
            <v-flex md6 class="mb-2">
                <v-layout row wrap>
                    <v-flex md8>
                        <v-text-field
                                background-color="#f4f4f4"
                                class="search-box--height"
                                clearable
                                flat
                                height="41"
                                label="Search Here"
                                solo
                                v-model="searchOrganization"
                        ></v-text-field>
                    </v-flex>
                    <v-flex md2>
                        <v-btn
                                color="bindezcolor"
                                class="mt-0"
                                depressed
                                small
                                dark
                        >
                            <span class="text-capitalize subheading font-weight-regular">Search</span>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex md4 class="text-xs-right">
                <v-btn
                        class="mr-0 mt-0"
                        color="bindezcolor"
                        @click="status('add')"
                        depressed
                        large
                        dark
                >
                    <v-icon left>add</v-icon>
                    <span class="text-capitalize font-weight-regular subheading">Add Organization</span>
                </v-btn>
            </v-flex>
        </v-layout>
        <v-dialog v-model="dialog" max-width="600" persistent>
            <v-card>
                <v-card-title primary-title v-if="dialogType === 'add' || dialogType === 'edit'">
                    <h4 class="title font-weight-medium" style="color:var(--bindez-green-color);">
                        {{ dialogType === 'add' ? 'Add Organization' : 'Edit Organization'}}
                    </h4>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex fill-height justify-center align-center"
                         v-if="dialogType === 'delete'">
                        <v-icon
                                color="bindezcolor"
                                :size="60"
                                class="mr-4"
                        >
                            fas fa-exclamation-circle
                        </v-icon>
                        <span class="title font-weight-bold">
                        <!-- Do you want to {{ dialogType === 'delete' ? 'Delete' : 'Ban' }} this organization? -->
                        Are you sure? This cannot be undone.
                    </span>
                    </div>
                    <template v-if="dialogType === 'add' || dialogType === 'edit'">
                        <v-text-field
                                :loading="formReady"
								solo
                                :height="41"
                                autofocus
                                color="bindezcolor"
                                label="Organization Name"
                                class="mb-2"
                                v-model="organizationForm.name"
                                :error-messages = "organizationNameErrors"
                        ></v-text-field>
                        <v-text-field
                                :loading="formReady"
                                solo
                                :height="41"
                                color="bindezcolor"
                                label="Limited Words Count"
                                class="mb-2"
                                v-model="organizationForm.word_count_limit"
                                :error-messages="wordsLimitErrors"
                                @blur="$v.$touch()"
                        ></v-text-field>
                        <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="organizationForm.expires_at"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                min-width="290px"

                        >
                            <v-text-field
                                    :height="41"
                                    :loading="formReady"
                                    solo
                                    slot="activator"
                                    v-model="organizationForm.expires_at"
                                    label="Expired Date"
                                    append-icon="event"
                                    color="bindezcolor"
                                    :error-messages="expiredDateErrors"
                                    readonly
                            ></v-text-field>
                            <v-date-picker v-model="organizationForm.expires_at" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                                <v-btn flat color="primary" @click="$refs.menu.save(organizationForm.expires_at)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </template>
                </v-card-text>
                <v-card-actions class="pa-3">
                    <v-btn
							outline
                            block
                            large
                            color="bindezcolor"
                            @click="dialogCancel"
                    >
                    <span class="text-capitalize subheading font-weight-regular"
                          style="color: #373737;">Cancel</span>
                    </v-btn>
                    <v-btn
                            block
                            large
                            dark
                            color="bindezcolor"
                            @click="dialogAction"
                    >
                        <span class="text-capitalize subheading font-weight-regular">
                            {{ dialogType | statusFilter(cases) }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table
                :search="searchOrganization"
                :headers="headers"
                :items="organizations"
                :pagination.sync="organizationPagination"
                :loading="!organizationAllFetchReady"
                item-key="id"
                class="elevation-2"
                select-all
                hide-actions
        >
            <template
                    slot="headers"
                    slot-scope="props"
            >
                <tr>
                    <th
                            v-for="header in props.headers"
                            :key="header.text"
                            class="text-xs-left subheading font-weight-regular"
                    >
                        {{header.text}}
                    </th>
                </tr>
            </template>
            <template
                    slot="items" slot-scope="props"
            >
                <tr>
                    <td class="body-2 table-text--color">{{props.item.name}}</td>
                    <td class="body-2 table-text--color">{{props.item.expires_at | formatDate}}</td>
					<td class="body-2 table-text--color">{{props.item.expires_at | checkExpireDate}}</td>
                    <td>
						<v-tooltip slot="append" top>
							<v-icon slot="activator" @click.stop="status('edit',props.item)" class="mr-3" size="13">
								far fa-edit
							</v-icon>
							<span>Edit</span>
						</v-tooltip>

						<v-tooltip slot="append" top>
							<v-icon slot="activator" @click.stop="status('delete',props.item)" class="mr-3" size="13">
								far fa-trash-alt
							</v-icon>
							<span>Delete</span>
						</v-tooltip>
                    </td>
                </tr>
            </template>
            <template slot="progress">
                <v-progress-linear indeterminate color="bindezcolor"></v-progress-linear>
            </template>
        </v-data-table>
        <div class="text-xs-center pt-2">
            <v-pagination
                    :length="organizationPages"
                    circle
                    color="bindezcolor"
                    total-visible="8"
                    v-model="organizationPagination.page">
            </v-pagination>
        </div>
    </div>
</template>

<script>
	import {mapState, mapActions, mapMutations} from 'vuex';
	import moment from 'moment';
	import globalSettingFilter from '@/mixins/globalSettingFilter';
	import {integer, required} from 'vuelidate/lib/validators';
	import {isValidOrgExpireDate} from "@/validators";

	export default {
		name: "OrganizationsSection",

		filters: {
			formatDate(raw) {
				return moment(raw).utc().format('YYYY-MM-DD')
			},

			checkExpireDate(date){
				if (moment(date).diff(moment(),'day') < 1 ) return "Expired"
				return "Active"
			}
		},

		mixins: [globalSettingFilter],

		data() {
			return {
				organizationForm: {
					name: "",
					word_count_limit: "",
					expires_at: "",
				},
				formReady: false,
				organizationListReady: false,
				dialog: false,
				dialogType: undefined,
				menu: false,
				organization: {},
				searchOrganization: '',
				page: 1,
				headers: [
					{text: "Name", width: "200", value: "name", sortable: false, class:'text-xs-left subheading'},
					{text: "Expired Date", width: "200", value: "expires_at",  sortable: false, class:'text-xs-left subheading'},
					{text: "Status", width: "200", value: "expires_at", sortable: false, class: 'text-xs-left subheading'},
					{text: "Options", width: "200", value: "option", sortable: false, class:'text-xs-left subheading'}
				],
                organizationPagination:{
					rowsPerPage: 10
                }
			}
		},

		computed: {
			...mapState('organizations', [
				'organizations',
			]),

			...mapState([
				'organizationAllFetchReady'
			]),

			wordsLimitErrors() {
				const errors = [];
				if (!this.$v.organizationForm.word_count_limit.$dirty) return errors;
				!this.$v.organizationForm.word_count_limit.required && errors.push("Words count limit required");
				!this.$v.organizationForm.word_count_limit.integer && errors.push("Words count limit must be integer")
				return errors
			},

			organizationNameErrors() {
				const errors = [];
				if (!this.$v.organizationForm.name.$dirty) return errors;
				!this.$v.organizationForm.name.required && errors.push("Organization name required");
				return errors
			},

			expiredDateErrors(){
				const errors = [];
				if(!this.$v.organizationForm.expires_at.$dirty) return errors;
				!this.$v.organizationForm.expires_at.required && errors.push("Expired date required");
				!this.$v.organizationForm.expires_at.isValidOrgExpireDate && errors.push("Organization expire date must be greater than current date");
				return errors
			},

			organizationPages() {
				if (this.organizationPagination.rowsPerPage == null ||
					this.organizationPagination.totalItems == null
				) return 0;

				return Math.ceil(this.organizationPagination.totalItems / this.organizationPagination.rowsPerPage);
			}
		},

		created() {
			this.fetchAllResource({
				resourceType: "Organization",
				resourceAction: "organizations/fetchOrganizations"
			}).then(data => {
				this.setOrganizations(data);
				this.$set(this.organizationPagination, "totalItems", data.length);
			});
		},

		methods: {
			...mapActions('organizations', [
				'addOrganization',
				'updateOrganization',
				'fetchOrganizations',
				'deleteOrganization'
			]),

			...mapActions([
				'fetchAllResource'
			]),

			...mapMutations('organizations', [
				'setOrganizations'
			]),

			...mapMutations('error', [
				"setErrorDialogMessage",
				"setErrorDialogStatus",
			]),

			resetOldData() {
				this.organizationForm.name = "";
				this.organizationForm.word_count_limit = "";
				this.organizationForm.expires_at = "";
			},

			status(type, value={}) {
				this.dialog = true;
				this.dialogType = type;
				if (type === 'add') {
					this.resetOldData();
					return;
				}
				this.organization = {};
				this.organization = value;
				this.organizationForm = Object.assign({}, this.organizationForm, {
					name: value.name,
					word_count_limit: value.word_count_limit,
					expires_at: moment(value.expires_at).utc().format("YYYY-MM-DD")
				});
			},

			dialogAction() {
				switch (this.dialogType) {
					case 'add':
						this.$v.$touch();
						if (!this.$v.$invalid)
							this.addNewOrganization();
						break;
					case 'edit':
						this.$v.$touch();
						if (!this.$v.$invalid)
							this.editExistingOrganization();
						break;
					case 'delete':
						this.deleteExistingOrganization();
						break;
				}
			},

			addNewOrganization() {
				this.formReady = true;
				this.addOrganization(this.organizationForm)
					.catch(this.ajaxErrorHandler)
					.then(() => {
						this.$ua.trackEvent("Organization", "click", "Add New Organization");
						this.resetOldData();
						this.dialog = false;
					}).finally(_ => {
					this.formReady = false;
				});
			},

			editExistingOrganization() {
				this.formReady = true;
				this.updateOrganization({
					data: this.organizationForm,
					organization_uuid: this.organization.uuid
				})
					.catch(this.ajaxErrorHandler)
					.then(({data}) => {
						this.$ua.trackEvent("Organization", "click", "Update Existing Organization");
                        ({
                            name: this.organization.name,
                            word_count_limit: this.organization.word_count_limit,
                            expires_at: this.organization.expires_at,
                        } = data.organization);
						this.dialog = false;
					}).finally(_ => {
					this.formReady = false;
				});
			},

			deleteExistingOrganization() {
				this.deleteOrganization(this.organization.uuid)
					.then(() => {
						this.$ua.trackEvent("Organization", "click", "Delete Existing Organization");
						this.dialog = false;
					})
			},

			dialogCancel() {
				this.$v.$reset();
				this.dialog = false;
			},

			pagination() {
				this.$ua.trackEvent("Paginate Organization", "click");
				this.organizationListReady = false;
				this.fetchOrganizations(this.page)
					.then(() => {
						this.organizationListReady = true;
					})
			},

			ajaxErrorHandler(error) {
				let {errorMessages} = this.$options;
				let {response: {data: {errors}}} = error;
				this.setErrorDialogStatus(true);
				this.setErrorDialogMessage(Object.keys(errors).map(error => errorMessages[error]).join(". "));
			}
		},

		errorMessages: {
			expires_at: "Expire date is required",
			name: "Organization name is required",
			word_count_limit: "Word count limit is required"
		},

		validations: {
			organizationForm: {
				name: {
					required,
				},
				word_count_limit: {
					required,
					integer,
				},
				expires_at: {
					required,
					isValidOrgExpireDate //custom validator for organization expire date
				}
			}
		},

	}
</script>

<style scoped>

    .v-btn.v-btn--small, .v-btn.v-btn--large {
        height: 41px;
    }

	.table-text--color{
        color: rgba(0,0,0,0.6);
    }

</style>
