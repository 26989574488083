import { render, staticRenderFns } from "./GlobalSettings.vue?vue&type=template&id=3f488f62&scoped=true&"
import script from "./GlobalSettings.vue?vue&type=script&lang=js&"
export * from "./GlobalSettings.vue?vue&type=script&lang=js&"
import style0 from "./GlobalSettings.vue?vue&type=style&index=0&id=3f488f62&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f488f62",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/var/www/html/insights/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VTab } from 'vuetify/lib'
import { VTabItem } from 'vuetify/lib'
import { VTabs } from 'vuetify/lib'
installComponents(component, {VApp,VCard,VContainer,VTab,VTabItem,VTabs})
