<template>
    <v-app>
        <v-card min-height="800" class="md12" flat style="top: 8px">
			<v-container fluid>
				<v-tabs hide-slider active-class="tabs-active" v-model="currentGSettingSection">
					<v-tab class="custom-border font-weight-regular">Users</v-tab>
					<v-tab class="custom-border font-weight-regular" v-if="authenticatedUser.is_superadministrator">Organizations</v-tab>
					<v-tab class="custom-border font-weight-regular" v-if="authenticatedUser.is_superadministrator">Source</v-tab>
					<v-tab-item style="min-height: 700px;" class="pa-3 tab-item-border">
						<UsersSection/>
					</v-tab-item>
					<v-tab-item v-if="authenticatedUser.is_superadministrator" class="pa-3 tab-item-border">
						<OrganizationsSection/>
					</v-tab-item>
					<v-tab-item v-if="authenticatedUser.is_superadministrator" class="pa-3 tab-item-border">
						<SourcesSection/>
					</v-tab-item>
				</v-tabs>
			</v-container>
        </v-card>
    </v-app>
</template>

<script>
	/*eslint-disable*/
	import UsersSection from "@/components/globalsettings/UsersSection";
	import OrganizationsSection from "@/components/globalsettings/OrganizationsSection";
	import SourcesSection from "@/components/globalsettings/SourcesSection";
	import authCurrentUser from "../mixins/authCurrentUser";

	export default {
		name: "GlobalSettings",
        mixins: [authCurrentUser],
		data() {
			return {
				currentGSettingSection: 'UsersSection',
			}
		},

		components: {
			SourcesSection,
			OrganizationsSection,
			UsersSection,
		}
	}
</script>

<style scoped>
.custom-border {
	margin: 0 1.5px 0 1.5px;
	border-width: 1px 1px 0;
	border-color: #e5e7e9;
	border-style: solid;
	border-top-left-radius: 9px;
	border-top-right-radius: 9px;
}

.tab-item-border {
	border: 1px solid #e2e2e2;
	margin-left: 2px;
}
</style>
