import { render, staticRenderFns } from "./UsersSection.vue?vue&type=template&id=7d1ff8bc&scoped=true&"
import script from "./UsersSection.vue?vue&type=script&lang=js&"
export * from "./UsersSection.vue?vue&type=script&lang=js&"
import style0 from "./UsersSection.vue?vue&type=style&index=0&id=7d1ff8bc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d1ff8bc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/var/www/html/insights/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VDataTable } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VPagination } from 'vuetify/lib'
import { VProgressLinear } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTooltip } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDataTable,VDialog,VFlex,VIcon,VLayout,VPagination,VProgressLinear,VSelect,VTextField,VTooltip})
