<template>
    <div>
        <v-layout justify-space-between>
            <v-flex md6 class="mb-2">
                <v-layout row wrap="">
                    <v-flex md8>
                        <v-text-field
                                flat
                                background-color="#f4f4f4"
                                v-model="sourceSearch"
                                label="Search Here"
								class="search-box--height"
								height="41"
                                solo
                                clearable
                        ></v-text-field>
                    </v-flex>
                    <v-flex md2>
                        <v-btn
                                color="bindezcolor"
                                class="mt-0"
                                depressed
                                small
                                dark
                        >
                            <span class="text-capitalize font-weight-regular subheading">Search</span>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-data-table
                :search="sourceSearch"
                :items="sourcesList"
                :loading="!sourcesListReady"
                :headers="headers"
                :pagination.sync="pagination"
                item-key="id"
                class="elevation-2"
                hide-actions
        >
            <template slot="progress">
                <v-progress-linear color="bindezcolor" indeterminate></v-progress-linear>
            </template>
            <template slot="items" slot-scope="props">
                <tr>
                    <td>
                        <v-avatar size="36">
                            <img :src="`//graph.facebook.com/${props.item.facebookId}/picture?width=60`"/>
                        </v-avatar>
                    </td>
                    <td>
                        <span class="body-2 zawgyi table-text--color">{{props.item.name}}</span>
                    </td>
                    <td class="body-2 table-text--color">{{props.item.facebookId}}</td>
                    <td class="body-2">
                        <a
                                class="zawgyi table-text--color"
                                style="text-decoration: none;"
                                :href="props.item.links"
                                target="_blank">
                            {{props.item.name}}
                        </a>
                    </td>
                    <td>
                        <v-btn
                                class="ml-0"
                                color="secondarycolor"
                                outline
                                @click.stop="copySource(props.item.links)"
                        ><span class="text-capitalize">Copy</span></v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <div class="text-xs-center pt-2">
            <v-pagination total-visible="8" v-show="sourcesListReady" circle color="bindezcolor" v-model="pagination.page" :length="pages"></v-pagination>
        </div>
        <v-snackbar
                v-model="snackbar"
                color="bindezcolor"
                top
                :timeout="3000"
        >
            <span class="font-weight-bold">Copied to clipboard!!</span>
            <v-btn
                    @click="snackbar = false"
                    dark
                    flat
            ><span class="text-capitalize">Close</span></v-btn>
        </v-snackbar>
    </div>
</template>

<script>
	import {mapActions, mapState} from 'vuex'
	import sourceSection from '@/mixins/sourceSection'

	export default {
		name: "SourcesSection",
		data() {
			return {
				sourceSearch: '',
				selectedSources: [],
                sourcesListReady: false,
				headers: [
                    {text: "", value: null, width:"70",sortable: false },
					{text: "Name", width:"255", value: "name", align:"left", sortable:false, class:"subheading ml-5 font-weight-regular"},
					{text: "FacebookID", width:"210", value: "facebookId", align:"left", sortable:false, class:"subheading font-weight-regular"},
					{text: "Links", width: "275", value: "links", align:"left", sortable:false, class:"subheading font-weight-regular"},
					{text: "Options", width: "90", value: "name", align:"left", sortable:false, class:"subheading font-weight-regular"}
				],
                pagination:{
					rowsPerPage: 100,
                    page: 1
                }
			}
		},
		methods: {
			...mapActions('monitors', [
				'fetchSources'
			]),
		},
        mixins:[sourceSection],
		computed: {
			...mapState('monitors', [
				'sources'
			]),

            sourcesList(){
				return this.sources.map(source => {
					source = source.split(":");
					return {
						id: source[1],
                        name: source[0],
                        facebookId: source[1],
                        links: `https://www.facebook.com/${source[1]}`
                    }
                })
            },

            pages(){
				if(this.pagination.totalItems === null || this.pagination.rowsPerPage === null) return 0
                return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
            }
		},

		created() {
			this.sourcesListReady = false;
			this.$set(this.pagination,"totalItems",null)
			this.fetchSources().then(() => {
				this.sourcesListReady = true;
                this.$set(this.pagination,"totalItems",this.sources.length)
            })
		}
	}
</script>

<style scoped>
    .v-btn.v-btn--small, .v-btn.v-btn--large {
        height: 41px;
    }

	.table-text--color{
        color: rgba(0,0,0,0.6);
    }
</style>
