import { render, staticRenderFns } from "./SourcesSection.vue?vue&type=template&id=433453b8&scoped=true&"
import script from "./SourcesSection.vue?vue&type=script&lang=js&"
export * from "./SourcesSection.vue?vue&type=script&lang=js&"
import style0 from "./SourcesSection.vue?vue&type=style&index=0&id=433453b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "433453b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/var/www/html/insights/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VDataTable } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VPagination } from 'vuetify/lib'
import { VProgressLinear } from 'vuetify/lib'
import { VSnackbar } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {VAvatar,VBtn,VDataTable,VFlex,VLayout,VPagination,VProgressLinear,VSnackbar,VTextField})
