<template>
    <v-layout row wrap>
        <v-flex md4>
            <v-text-field
                    background-color="#f4f4f4"
                    class="search-box--height"
                    clearable
                    flat
                    height="41"
                    label="Search Here"
                    solo
                    v-model="userSearch"
            ></v-text-field>
        </v-flex>
        <v-flex md4>
            <v-btn
                    color="bindezcolor"
                    class="mt-0"
                    depressed
                    dark
                    small
            >
                <span class="text-capitalize subheading font-weight-regular">Search</span>
            </v-btn>
        </v-flex>
        <v-flex md4 class="text-xs-right">
            <v-btn
                    class="mr-0 mt-0"
                    color="bindezcolor"
                    @click="status('add')"
                    depressed
                    large
                    dark
            >
                <v-icon
                        left
                >
                    add
                </v-icon>
                <span class="text-capitalize font-weight-regular subheading">Add User</span>
            </v-btn>
        </v-flex>
        <v-flex md12>
            <v-dialog v-model="dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title primary-title v-if="dialogType === 'add' || dialogType === 'edit'">
                        <h4 class="title font-weight-medium" style="color:var(--bindez-green-color);">{{ dialogType === 'add' ? 'Add User' : 'Edit User'}}</h4>
                    </v-card-title>
                    <v-card-text>
                        <!-- v-if=dialogType === 'ban' || dialogType === 'delete' -->
                        <div class="d-flex fill-height justify-center align-center "
                             v-if="dialogType === 'warning' || dialogType === 'ban' || dialogType === 'delete'">
                            <v-icon
                                    color="bindezcolor"
                                    :size="60"
                                    class="mr-4"
                            >
                                fas fa-exclamation-circle
                            </v-icon>
                            <!-- v-show="dialogType === 'ban' || dialogType === 'delete' -->
                            <span v-show="(user.status === 'active' && dialogType === 'ban')  || dialogType === 'delete'" class="title font-weight-bold">
                                Do you want to {{ dialogType === 'ban' ? 'Ban' : 'Delete'}} <span>"{{user.name}}"</span> ? If you do, this user can't be login
                            </span>
                            <span v-show="user.status === 'banned' && dialogType === 'ban' " class="title font-weight-bold">
                                Do you want to active <span>"{{user.name}}"</span> ?
                            </span>
                        </div>
                        <template v-if="dialogType === 'add' || dialogType === 'edit'">
                            <v-text-field
									:loading="userFormReady"
                                    solo
                                    :height="41"
                                    color="bindezcolor"
                                    label="Email Address"
                                    class="mb-2"
									v-model="userForm.email"
                                    :error-messages="emailError"
                            ></v-text-field>
                            <v-select
									:loading="userFormReady"
                                    solo
                                    :height="41"
                                    color="bindezcolor"
                                    label="Organization"
                                    class="mb-2"
                                    :items="organizationFilterList"
                                    item-text="name"
                                    item-value="uuid"
                                    v-model="userForm.organization_uuid"
                                    :error-messages="orgError"
                            >
                            </v-select>
                            <v-select
									:loading="userFormReady"
                                    solo
                                    :height="41"
                                    color="bindezcolor"
                                    label="Role"
                                    :items="roles"
                                    item-text="name"
                                    item-value="uuid"
                                    class="mb-2"
                                    v-model="userForm.role_uuid"
                                    :error-messages="roleError"
                            >
                            </v-select>
                        </template>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-btn
                                outline
                                block
                                large
                                color="bindezcolor"
                                @click="dialogCancel"
                        >
                        <span class="text-capitalize subheading font-weight-regular"
                              style="color: #373737;">Cancel</span>
                        </v-btn>
                        <v-btn
                                block
                                large
                                dark
                                color="bindezcolor"
                                @click="dialogAction"
                        >
                            <span class="text-capitalize subheading font-weight-regular">
                                {{(dialogType !== 'ban' ? dialogType : this.user.status === 'active' ? 'ban' : 'actives') | statusFilter(cases)}}
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-data-table
                    :search="userSearch"
                    :headers="headers"
                    :items="userList"
                    :pagination.sync="userPagination"
                    hide-actions
                    item-key="id"
                    class="elevation-2"
                    :loading="!userAllFetchReady"
            >
                <template
                        slot="items" slot-scope="props"
                >
                    <tr>
                        <td class="body-2 table-text--color">{{props.item.name}}</td>
                        <td class="body-2 table-text--color">{{props.item.email}}</td>
                        <td class="body-2 table-text--color">{{props.item.organization.name}}</td>
                        <td class="body-2 table-text--color">
                        <span class="text-capitalize" v-for="roles in Object.keys(props.item.roles)">
                            {{roles}}
                        </span>
                        </td>
                        <td class="body-2 text-capitalize table-text--color">{{props.item.status}}</td>
                        <td>
							<v-tooltip slot="append" top>
								<v-icon slot="activator" @click.stop="status('edit',props.item)" class="mr-2" size="13">
									far fa-edit
								</v-icon>
								<span>Edit</span>
							</v-tooltip>

							<v-tooltip slot="append" top>
								<v-icon slot="activator" @click.stop="status('ban',props.item)" :disabled="props.item.status === 'pending'" class="mr-2"
                                    size="13">
									{{ props.item.status | statusFilter(cases) }}
								</v-icon>
								<span class="text-capitalize">
									{{ props.item.status === 'active' ? 'Ban' : props.item.status === 'banned' ? 'Active' : 'Pending' }}
								</span>
							</v-tooltip>

							<v-tooltip slot="append" top>
								<v-icon slot="activator" @click.stop="status('delete',props.item)"  size="13">
									far fa-trash-alt
								</v-icon>
								<span>Delete</span>
							</v-tooltip>

                        </td>
                    </tr>
                </template>
                <template slot="progress">
                    <v-progress-linear indeterminate color="bindezcolor"></v-progress-linear>
                </template>
            </v-data-table>
            <div class="text-xs-center pt-2">
                <v-pagination total-visible="8" color="bindezcolor" v-show="usersPages > 0" circle v-model="userPagination.page" :length="usersPages"></v-pagination>
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
	/*eslint-disable*/
    import {required, email} from 'vuelidate/lib/validators'
	import {mapState, mapActions, mapMutations} from 'vuex';
	import authCurrentUser from "@/mixins/authCurrentUser";
	import globalSettingFilter from '@/mixins/globalSettingFilter';

	export default {
		name: "UsersSection",

		mixins: [globalSettingFilter, authCurrentUser],

		data() {
			return {
				userFormReady: false,
				dialogType: undefined,
				dialog: false,
				userSearch: '',
                userForm:{
					organization_uuid:"",
                    email:"",
                    role_uuid:""
                },
				user: {},
				headers: [
					{text: "Name", value: "name", sortable: false, class:"text-xs-left subheading font-weight-regular"},
					{text: "Email Address", value: "email", sortable: false, class:"text-xs-left subheading font-weight-regular"},
					{text: "Organization", value: "organization", sortable: false, class:"text-xs-left subheading font-weight-regular"},
					{text: "Role", width:"150", value: "role", sortable: false, class:"text-xs-left subheading font-weight-regular"},
					{text: "Status", width:"90", value: "status", sortable: false, class:"text-xs-left subheading font-weight-regular"},
					{text: "Options", value: "name", sortable: false, class:"text-xs-left subheading font-weight-regular"}
				],
                userPagination:{
					rowsPerPage: 10
                },
			}
		},

		computed: {
			emailError(){
				const errors = [];
				if(!this.$v.userForm.email.$dirty) return errors;
				!this.$v.userForm.email.required && errors.push("Email Required");
				!this.$v.userForm.email.email && errors.push("Must be Valid Email");
				return errors;
			},

			roleError(){
				const errors = [];
				if (!this.$v.userForm.role_uuid.$dirty) return errors;
				!this.$v.userForm.role_uuid.required && errors.push("Role Must Select");
				return errors
			},

			orgError(){
				const errors = [];
				if (!this.$v.userForm.organization_uuid.$dirty) return errors;
				!this.$v.userForm.organization_uuid.required && errors.push("Organization Must Select");
				return errors
			},

			usersPages() {
				if (this.userPagination.rowsPerPage == null ||
					this.userPagination.totalItems == null
				) return 0;

				return Math.ceil(this.userPagination.totalItems / this.userPagination.rowsPerPage);
			},

			...mapState('users', [
				'roles'
			]),

			...mapState([
				'organizationFilterList',
				'organizationIndexList',
				'userAllFetchReady'
			]),

			...mapState('monitors',[
				'users'
			]),

			userList: {
				get() {
					if (!!this.users) {
						return this.users.map(user => {
							let org = this.organizationIndexList[`${user.organization_uuid}`];
							return {
								uuid: user.uuid,
								name: user.name,
								email: user.email,
								organization: {
									uuid: user.organization_uuid,
									name: !!org ? org.name : ""
								},
								roles: user.roles,
								status: user.status
							}
						})
					}
				},
				set(users){
					this.setUsers(users);
				}
			}
		},

		watch: {
			users(users){
				this.$set(this.userPagination, "totalItems", users.length);
			}
		},

		created() {
			if (this.authenticatedUser.is_superadministrator || this.authenticatedUser.is_administrator) {
				this.fetchOrganizationFilterList();
				this.fetchRoles();
			}
			this.fetchAllAvailableUsers();
		},

		methods: {
			...mapActions([
				'fetchOrganizationFilterList',
				'fetchAllResource'
			]),

			...mapActions('monitors', [
				'fetchUsers'
			]),

			...mapActions('users', [
				'banUser',
				'addUser',
				'updateUser',
				'deleteUser',
				'fetchRoles'
			]),

			...mapMutations('monitors', [
				'setUsers'
			]),

			...mapMutations('error',[
				"setErrorDialogStatus",
				"setErrorDialogMessage",
				"setErrorStatusCode"
			]),

			resetOldData() {
				this.userForm.role_uuid = "";
				this.userForm.organization_uuid = "";
				this.userForm.email = "";
			},

			dialogAction() {
				switch (this.dialogType) {
					case "add":
						this.$v.$touch();
						if (!this.$v.$invalid) this.addNewUser();
						break;
					case "edit":
						this.$v.$touch();
						if (!this.$v.$invalid) this.updateExistingUser();
						break;
					case "ban":
						this.banExistingUser();
						break;
					case "delete":
						this.deleteExistingUser();
						break;
				}
			},

			addNewUser() {
				this.userFormReady = true;
				this.addUser(this.userForm)
					.then(({data}) => {
						this.$ua.trackEvent("User", "click", "Add New User");
						this.userList = [data.user, ...this.users];
						this.resetOldData();
						this.dialog = false;
					})
					.catch(this.ajaxErrorHandler)
					.finally(_ => {
						this.userFormReady = false;
					});
			},

			updateExistingUser() {
				this.userFormReady = true;
				this.updateUser({
					data: this.userForm,
					user_uuid: this.user.uuid
				}).then(({data}) => {
					let {email, roles, organization_uuid} = data.user;
					this.user.organization = this.organizationIndexList[`${organization_uuid}`];
					this.user.email = email;
					this.user.roles = roles;
					this.$ua.trackEvent("User", "click", "Update Existing User");
					this.dialog = false;
					this.resetOldData();
				})
					.catch(this.ajaxErrorHandler)
					.finally(_ => {
						this.userFormReady = false;
					})
			},

			banExistingUser() {
				this.banUser(this.user.uuid)
					.then(({data}) => {
						this.user.status = data.user.status;
						this.$ua.trackEvent("User", "click", "Ban User");
					})
					.finally(_ => {
						this.dialog = false
					});
			},

			deleteExistingUser() {
				this.deleteUser(this.user.uuid)
					.then(_ => {
						this.$set(this.userPagination, "page", 1);
						this.$ua.trackEvent("User", "click", "Delete User");
						this.fetchAllAvailableUsers();
					})
					.catch(({response}) => {
						let{data:{error:{message}}, status}  = response;
						if(status === 401){
							this.setErrorDialogStatus(true);
							this.setErrorDialogMessage(`${message} If you want to delete this user please contact to us`);
						}
					})
					.finally(_ => {
						this.dialog = false;
					});
			},

			ajaxErrorHandler(error) {
				let {errorMessages} = this.$options;
				let {response: {data: {errors}}} = error;
				if(errors.hasOwnProperty("email")) errorMessages.email = errors.email;
				this.setErrorDialogStatus(true);
				this.setErrorDialogMessage(Object.keys(errors).map(error => errorMessages[error]).join(". "))
			},

			dialogCancel() {
				this.$v.$reset();
				this.dialog = false;
			},

			status(type, value = {}) {
				this.dialog = true;
				this.dialogType = type;
				if(type === 'add') {
					this.resetOldData();
					this.userForm.organization_uuid = this.authenticatedUser.is_administrator ? this.authenticatedUser.organization_uuid : "";
					return;
				}
				this.user = {};
				this.user = value;
				this.userForm = Object.assign({}, this.userForm, {
					email: value.email,
					organization_uuid: value.organization.uuid,
					role_uuid: Object.values(value.roles)[0].uuid,
				});
			},

			fetchAllAvailableUsers(){
				this.fetchAllResource({
					resourceType: 'User',
					resourceAction: 'monitors/fetchUsers'
				}).then(this.setUsers);
			}
		},


		errorMessages: {
			//error msgs are not part of reactivity system.
			organization_uuid: "Organization must be selected",
			role_uuid: "Role must be selected",
		},

        validations:{
			userForm:{
				email:{
					required,
                    email
                },
                role_uuid:{
					required,
                },
                organization_uuid:{
					required,
                }
            }
        },
	}
</script>

<style scoped>
    .user-table__group--size {
        max-width: 320px;
    }

    .v-btn.v-btn--small, .v-btn.v-btn--large{
        height:41px ;
    }

	.table-text--color{
        color: rgba(0,0,0,0.6);
    }

</style>
