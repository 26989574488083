export default {
	data() {
		return {
			cases: {
				//pending: "fas fa-spinner fa-spin",
				pending: "fas fa-ban",
				//active: "fas fa-ban",
				active: "fas fa-lock",
				banned: "fas fa-unlock",
				ban: "Ban Now",
				actives: "Active Now",
				delete: "Yes, Delete",
				warning: "Got it!",
				add: "Add",
				edit: "Done"
			}
		};
	}
};
