import moment from 'moment';

const NOT_ALLOWED_REGEX = /[~`!@$%\^&*()\-+={}\[\]|\\/:;"'<>,.?]/;
const INCLUDE_REGEX = /[a-zA-Zက-အ]/;

function notAllowSpecialCharacter(raw){
	return !NOT_ALLOWED_REGEX.test(raw);
}

function includeSpecialCharacter(raw){
	return /[#_]/.test(raw) ? INCLUDE_REGEX.test(raw) : true;
}

function isValidOrgExpireDate(raw){
	return !(moment(raw).diff(moment(), "days") < 0 );
}

export{
	notAllowSpecialCharacter,
	includeSpecialCharacter,
	isValidOrgExpireDate
}
